/*
 * Responsive layout
 *
 * responsive-flex-layout is the site BEM component for gallery style lists using CSS3 Flex.
 *
 * 1. Naming convention for modifiers. For example:
 *    .responsive-flex-layout--t2-d3 translates to layout of 2 columns for
 *    tablet breakpoint ('t' for tablet) and 3 columns for desktop and up
 *    ('d' for desktop).
 *
 * 2. We add and additional class (responsive-layout--item) because
 *    views converts the correct class (responsive-layout__slat).
 *    See https://www.drupal.org/node/1371118
 */

/* t2-d3 */
.responsive-flex-layout--t2-d3,
.responsive-flex-layout--t2-d3 .view-content { /* [1] */
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  .responsive-layout--item, /* [2] */
  .responsive-layout__item {
    flex: 0 0 100%;
    @include respond-to(tablet) {
      flex: 0 0 48%;
    }
    @include respond-to(desktop-and-up) {
      flex: 0 0 31%;
    }
  }
}

/* t2-d4 */
.responsive-flex-layout--t2-d4,
.responsive-flex-layout--t2-d4 .view-content {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  
  .responsive-layout--item, /* [2] */
  .responsive-layout__item {
    flex: 0 0 100%;
    @include respond-to(tablet) {
      flex: 0 0 48%;
    }
    @include respond-to(desktop-and-up) {
      flex: 0 0 23%;
    }
  }
}


/* t2-d5 */
.responsive-flex-layout--t2-d5,
.responsive-flex-layout--t2-d5 .view-content {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  
  .responsive-layout--item,
  .responsive-layout__item {
    flex: 0 0 100%;
    @include respond-to(tablet) {
      flex: 0 0 48%;
    }
    @include respond-to(desktop-and-up) {
      flex: 0 0 18%;
    }
  }
}

/* d2 */
.responsive-flex-layout--d2,
.responsive-flex-layout--d2 .view-content {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  
  .responsive-layout--item,
  .responsive-layout__item {
    flex: 0 0 100%;
    @include respond-to(tablet-and-up) {
      flex: 0 0 48%;
    }
  }
}

