#mmenu_top {
  height: 81px;
  overflow: hidden;
  background-color: transparent;
  .logged-in & {
    margin-top: 30px;
  }
  > .mm-list {
    background-color: #f8f9fa;
    box-shadow: none;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    @extend .site__inner;

    @include respond-to(wide-large) {
     
    }

    /* Site gutters */
    padding: 0 $spacing-base;
    @include respond-to(desktop-and-up) {
      padding: 0 ($spacing-double * 2);
    }
  }
}

#mmenu_right {
  .mmenu-block-title {
    display: none;
  }
  #block-mmenu-search {
    display: none;
    padding: 30px 40px 30px 15px;
    @include respond-to(mobile-and-down){
      display: block;
    }
  }
}