.social-share {
  .block-title {
    margin: 2px;
    font-family: $font-family-italic;
    font-size: $font-size-small;
  }
  > div > a {
    display: block;
    float: left;
    clear: both;
    font: 0/0 a;
    text-shadow: none;
    @include border-radius(3px);
    @extend .icon--pseudo;
    @extend .icon--absolute;
    width: 32px;
    height: 32px;
    position: relative;
    color: $gray-alt-light;
    padding: 5px;
    margin: 0 0 1px 1px;
    &:before {
      top: 0;
      left: 6px;
      font-size: 20px;
      line-height: 32px;
    }
    &:hover,
    &:focus {
      &:before {
        color: $white;
      }
    }
  }
}

.social-share-facebook {
  @extend .icon--facebook;
  background-color: $blue-dark;
}

.social-share-twitter {
  @extend .icon--twitter;
  background-color: $blue-light;
}

.social-share-linkedin {
  @extend .icon--linkedin;
  background-color: $blue;
}

.social-share-email {
  @extend .icon--envelope;
  background-color: $gray-dark;
}
