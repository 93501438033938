/* Shop page */
.view-kidscan-shop {

  li.views-row {
    @include box-shadow(0 0 $spacing-quarter rgba(0, 0, 0, 0.4));
    margin-bottom: 2 * $spacing-base;

    > div.node {
      padding: $spacing-base $spacing-quarter;
      text-align: center;

      .field-name-field-image {
        margin-bottom: $spacing-base;
      }
      .field-name-title {
        height: 52.8px;
        overflow: hidden;
        h3 {
          margin: 0;
          font-weight: normal;
          font-family: 'NationalRegular';
        }
        a {
          color: black;
        }
      }
      .form-item-quantity {
        display: none;
      }
      .field-name-commerce-price {
        font-family: 'NationalRegular';
        font-size: $font-size-h3;
      }
    }

  }
}

/* captcha positioning on review page */
#commerce-checkout-form-review {
  .captcha {
    margin-bottom: $spacing-base;
  }
}

.block-commerce-cart {
  margin-right: 60px;
  .header & {
    @include make-xs-column(4);
    @include respond-to(tablet-and-up) {
      @include make-xs-column(5);
    }
    display: block;
    font-size: 20px;
    padding-top: $spacing-quarter;

    .cart-text {
      display: block;
      @include respond-to(tablet-and-up) {
        margin-left: 15px;
      }
      padding: $spacing-quarter 0;
      @include img-retina("../images/Shopping-Cart-32.png", "../images/Shopping-Cart-64.png", 32px, 32px);
      background-repeat: no-repeat;
      background-position: left 1px;
      font-weight: bold;
      padding-left: 30px;
    }

    @include respond-to(desktop-and-up) {
      @include make-md-column(1);    /* cart is wider than this, but we rely on menu to be smaller so we push into the menu a bit, which works for desktop. And because we overlap, use z-index to correct. */
      padding-left: $spacing-base;
      padding-top: 0;
      z-index: 3;

      .cart-text {
        font-size: 22px;
        line-height: $line-height-base;
      }

    }

  }
  @include respond-to(mobile-and-up) {
    @include make-xs-column(12);
  }
}

/* We only show cart if there's actual contents */
.commerce-ajax-cart-loader {
  display: none;
}

#commerce-ajax-cart-preview {
  background: transparent url("../images/triangle-pink.png") no-repeat 95% top;
  padding: 10px 0 0 0;
  width: 400px;
  box-shadow: none;

  &.no-triangle {
    background: none;
    position: fixed;
  }

  .view-commerce-cart-block,
  .empty-cart {
    background: $white;
    border-top: 1px solid $pink;
    padding: $spacing-half $spacing-half $spacing-half $spacing-half;
    @include box-shadow(0 0 $spacing-quarter rgba(0, 0, 0, 0.4));

    a:link,
    a:visited {
      color: inherit;
    }

    .views-row {
      border-bottom: 1px solid #ddd;
      clear: left;
      height: 76px;
      margin-bottom: 4px;
    }

    .views-field-field-image {
      float: left;
      margin-right: 5px;
      margin-top: 5px;
    }

    .views-field-line-item-title {
      padding-top: 0;
      font-size: 22px;
      line-height: 1.1em;
      max-height: 2.1em;
      overflow: hidden;
    }

    .views-field-quantity,
    .views-field-commerce-unit-price {
      display: inline-block;
    }

    .line-item-total-label {
      font-weight: normal;
    }

    .view-footer {
      margin-top: 12px;
    }

    .line-item-summary {
      margin-bottom: 0;
    }

    .links {
      margin-bottom: 0;
    }

    .links li.first,
    .links li.first a {
      background-color: $blue;;
    }

    .links li a {
      color: $white;
      padding-left: 1.25 * $spacing-base;
      padding-right: 1.25 * $spacing-base;
    }

  }

}
