/*
 * Responsive layout
 *
 * responsive-layout is the site BEM component for lists of stuff.
 *
 * 1. We add and additional class (responsive-layout--item) because views converts
 * the correct class (responsive-layout__slat) - See https://www.drupal.org/node/1371118
 */

.responsive-layout__content {
  > ul {
    display: block;
    clear: both;
    margin: 0;
    padding: 0;
    > li {
      margin: 0 0 $spacing-base;
      padding: 0;
      list-style: none;
      @include respond-to(mobile-and-down) {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}

/*
 * Variant 1
 *
 * Description of default desktop display: This responsive-layout variant
 * features multiples of 3 list items displayed vertically 3 per row.
 * Only on mobile do we collapse to 1 item per row.
 */
.responsive-layout--variant-1 {
  .responsive-layout--item, /* [1] */
  .responsive-layout__item {
    @include respond-to(tablet-large-and-down) {
      @include span(6 of 12 before);
      &:nth-child(2n+1) {
        @include span(6 of 12 before first);
        clear: both;
      }
      &:nth-child(2n+2) {
        @include span(6 of 12 before last);
      }
    }
    @include respond-to(mobile) {
      &:nth-child(odd) {
        @include span(12 of 12 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(12 of 12 before last);
      }
    }
    @include respond-to(tablet-large-and-up) {
      @include span(4 of 12 before);
      &:nth-child(3n+1) {
        @include span(4 of 12 before first);
        clear: both;
      }
      &:nth-child(3n+3) {
        @include span(4 of 12 before last);
      }
    }
  }
  .node-page & {
    margin: $spacing-base 0;
    .responsive-layout--item, /* [1] */
    .responsive-layout__item {
      margin-bottom: $spacing-base;
    }
  }
}

/*
 * Variant 2
 *
 * Description of default desktop display:
 * This responsive-layout variant features even numbers of list items displayed
 * vertically 2 per row.
 */
.responsive-layout--variant-2 {
  .responsive-layout--item, /* [1] */
  .responsive-layout__item {
    @include respond-to(tablet-large-and-up) {
      &:nth-child(odd) {
        @include span(6 of 12 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(6 of 12 before last);
      }
    }
  }
}

/*
 * Variant 3
 *
 * Description of default desktop display:
 * This responsive-layout variant features even numbers of list items displayed
 * vertically 3 per row.
 */
.responsive-layout--variant-3 {
  position: relative;
  .responsive-layout--item, /* [1] */
  .responsive-layout__item {
    margin-bottom: 40px;
    @include respond-to(tablet-large) {
      &:nth-child(odd) {
        @include span(6 of 12 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(6 of 12 before last);
      }
    }
    @include respond-to(desktop-and-up) {
      margin-bottom: 0;
      @include span(4 of 12 before);
      &:nth-child(3n+1) {
        @include span(4 of 12 before first);
        clear: both;
      }
      &:nth-child(3n+3) {
        @include span(4 of 12 before last);
      }
    }
  }
  .node-page & {
    margin: $spacing-base 0;
    .responsive-layout--item, /* [1] */
    .responsive-layout__item {
      margin-bottom: $spacing-base;
    }
  }
  
}

.responsive-layout--variant-4 {
  .responsive-layout--item, /* [1] */
  .responsive-layout__item {
    margin-bottom: 40px;
    @include respond-to(tablet-large) {
      &:nth-child(odd) {
        @include span(6 of 12 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(6 of 12 before last);
      }
    }
    @include respond-to(desktop-and-up) {
      margin-bottom: 0;
      @include span(3 of 12 before);
      &:nth-child(4n+1) {
        @include span(3 of 12 before first);
        clear: both;
      }
      &:nth-child(4n+3) {
        @include span(3 of 12 before last);
      }
    }
  }
}


/*
 * Variant 5
 *
 * Description of default desktop display:
 * This responsive-layout variant features even numbers of list items displayed
 * vertically 5 per row.
 */
.responsive-layout--variant-5 {
  .responsive-layout--item, /* [1] */
  .responsive-layout__item {
    @include respond-to(mobile) {
      &:nth-child(odd) {
        @include span(12 of 12 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(12 of 12 before last);
      }
    }
    @include respond-to(tablet-and-up) {
      @include span(2.4 of 12 before);
      &:nth-child(5n+1) {
        @include span(2.4 of 12 before first);
        clear: both;
      }
    }
  }
  .node-page & {
    margin: $spacing-base 0;
    .responsive-layout--item, /* [1] */
    .responsive-layout__item {
      margin-bottom: $spacing-base;
    }
  }
}
