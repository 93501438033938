.breadcrumb {
  font-size: em(14);
  margin-top: $spacing-base;
  > .breadcrumb__item {
    + li {
      &:before {
        padding: 0 $spacing-quarter 0 $spacing-half;
      }
    }
  }
}

.breadcrumb__item {
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}
