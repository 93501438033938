.block--stats {
  position: relative;
  margin-bottom: 30px;
  background: white;
  h2 {
    @extend .has-heading-divider;
    //@include heading-divider($heading-color: $blue-dark, $divider-color: $blue-dark);
  }
  .block__content {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-end;
  }
  img {
    width: 100%;
    max-width: 280px;
    margin: 20px;
  }
  small {
    color: $blue;
  }
}

.block--infographics {
  position: relative;
  background: $blue;
  h2.block-title {
    @include heading-divider($heading-color: white, $divider-color: #5F7FAE);
  }
  .block__content {
    position: relative;
  }
  .donations-infographic {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
    color: white;
    text-align: center;
    &__item {
      flex: 1;
      max-width: 50%;
      min-width: 50%;
      margin-bottom: 40px;
      padding: 0 10px;
      font-size: 30px;
      line-height: 1;
      font-family: $font-family-italic;
      img {
        margin-bottom: 20px;
      }
    }
    @include respond-to(tablet-and-up) {
      margin: 0 -30px;
      &__item {
        max-width: 20%;
        min-width: 220px;
        padding: 0 30px;
      }
    }
    
  }
  &.break-out:before {
    background: $blue;
  }
  
  // Alternate infographic layout on white
  &-alternate {
    background: white;
    h2.block-title {
      @include heading-divider($heading-color: $blue-dark);
    }
    .donations-infographic {
      color: $blue-dark;
      font-size: 32px;
      &__item {
        p {
          margin-top: 15px;
          line-height: 1.5;
          font-size: 18px;
          color: #373737;
          font-family: $font-family-base;
        }
      }
    }
    &.break-out:before {
      background: inherit;
    }
  }
}

.block--latest-updates {
  position: relative;
  background: white;
  h2.block-title {
    //@include heading-divider($heading-color: $blue-dark, $divider-color: $gray-alt-light);
  }
  .more-link {
    position: relative;
    clear: both;
    padding-top: 60px;
    @extend .text-center;
    a {
      @extend .btn;
      @extend .btn-inverted;
      @extend .btn-blue;
      &:hover {
        color: $pink !important;
      }
    }
  }
}

.block--testimonials {
  position: relative;
  //width: auto !important;
  background: #FFF56E;
  .flexslider {
    background: none;
  }
  h2.block-title {
    @include heading-divider($heading-color: $gray-alt-dark, $divider-color: transparentize(#333, .8));
  }
  .block__content {
    position: relative;
  }
  &.break-out:before {
    background: #FFF56E;
  }
}

.block--ways-to-help {
  position: relative;
  background: white;
  .more-link {
    position: relative;
    clear: both;
    padding-top: 60px;
    @extend .text-center;
    a {
      @extend .btn;
      @extend .btn-inverted;
    }
  }
}

.block--get-in-touch {
  position: relative;
  background: #00ABBC;
  color: white;
  .block__content {
    position: relative;
  }
  h2 {
    position: relative;
    font-size: 45px;
  }
  p.lead {
    font-size: 22px;
  }
  &:before {
    background: #00ABBC;
  }
  @include respond-to(tablet-and-up) {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    align-content: center;
    min-height: 465px;
    padding-left: 50%;
    background: url(../images/boy-on-bench.png) no-repeat left bottom, linear-gradient(to bottom, #ffffff 1%, #ffffff 10%, #00abbc 10%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00abbc',GradientType=0 );
    &:before {
      background: url(../images/boy-on-bench.png) no-repeat left bottom, linear-gradient(to bottom, #ffffff 1%, #ffffff 10%, #00abbc 10%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00abbc',GradientType=0 );
    }
  }
  @include respond-to(tablet-large-and-up) {
    padding-left: 340px;
    h2 {
      font-size: 55px;
    }
    p.lead {
      font-size: 26px;
    }
  }
}

.block--donate {
  margin: 0 auto;
  padding: 20px 0px;
  @include respond-to(tablet-large-and-up) {
    max-width: 980px;
    padding: 40px 60px;
  }
  h2 {
    line-height: 1.2;
    font-family: $font-family-base;
  }
}