/*
 * Media
 *
 * Media elements can be added via WYSIWYG templates.
 *
 */

.media {
  margin: 0 0 $spacing-base 0;
  @include respond-to(tablet-and-up) {
    margin: 0 0 $spacing-prose-base 0;
  }
  &.image-caption {
    > p {
      clear: both;
      color: lighten($gray-dark, 15%);
      padding: $spacing-half 0 $spacing-quarter;
      font-size: 85%;
      font-family: $font-family-bold;
      @include respond-to(tablet-and-up) {
        padding: $spacing-prose-half 0 $spacing-prose-quarter;
      }
    }
  }
  &.pull-left,
  &.pull-right {
    width: 100%;
    @include respond-to(tablet-and-up) {
      width: auto;
    }
    > img {
      height: auto !important;
    }
  }
  &.pull-left {
    margin: em(4) $spacing-base $spacing-base 0;
    @include respond-to(tablet-and-up) {
      margin: em(8) $spacing-prose-double $spacing-prose-base 0;
    }
  }
  &.pull-right {
    margin: em(4) 0 $spacing-base $spacing-base;
    @include respond-to(tablet-and-up) {
      margin: em(8) 0 $spacing-prose-base $spacing-prose-double;
    }
  }
}

.media--featured {
  margin: $spacing-half 0 $spacing-base;
  @include respond-to(tablet-and-up) {
    margin: $spacing-prose-base 0 $spacing-prose-base;
  }
}

.media--gallery {
  > .field-items {
    @extend .responsive-layout--variant-3;
    > .field-item {
      @extend .responsive-layout__item;
      > a {
        @extend .media;
        @extend .responsive-ratio;
        @extend .responsive-ratio--3-2;
        display: block;
        &:before {
          content: attr(title);
          display: block;
          z-index: 20;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          width: 100%;
          padding: $spacing-quarter 0;
          background-color: rgba($gray-alt-lighter, 0.8);
        }
        > img {
        }
      }
    }
  }
}
/* @todo Move this to its own partial */
#cboxContent {
  margin-bottom: $spacing-double;
}
#cboxTitle {
  font-size: $font-size-h3;
  top: inherit;
}
#cboxCurrent {
  top: inherit;
}
