/*
 * Breakpoints
 *
 * Map Bootstrap breakpoints to nice mixins
 */
//$screen-xs: 480px;
//$screen-sm: 768px;
//$screen-md: 992px;
//$screen-lg: 1200px;
//$screen-xlg: 1400px;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 1200px; /* Knock out the 992px breakpoint, go straight to mobile. */
$screen-lg: 1400px; /* Make anything larger than 1400 'wide'. */
$screen-xlg: 1400px;

@mixin respond-to( $media ) {
  @if $media == mobile {
    @include breakpoint(max-width $screen-xs) { @content; }
  }
  @else if $media == tablet {
    @include breakpoint((min-width $screen-xs + 1) (max-width $screen-md - 1)) { @content; }
  }
  @else if $media == tablet-large {
    @include breakpoint((min-width $screen-sm + 1) (max-width $screen-md - 1)) { @content; }
  }
  @else if $media == desktop {
    @include breakpoint((min-width $screen-md + 1) (max-width $screen-lg - 1)) { @content; }
  }
  @else if $media == wide {
    @include breakpoint((min-width $screen-lg + 1) (max-width $screen-xlg - 1)) { @content; }
  }
  @else if $media == wide-large {
    @include breakpoint(min-width $screen-xlg) { @content; }
  }

  // If inside of view, or smaller views
  @else if $media == mobile-and-down {
    @include breakpoint(max-width $screen-xs) { @content; }
  }
  @else if $media == tablet-and-down {
    @include breakpoint(max-width ($screen-md - 1)) { @content; }
  }
  @else if $media == tablet-large-and-down {
    @include breakpoint(max-width ($screen-sm - 1)) { @content; }
  }
  @else if $media == desktop-and-down {
    @include breakpoint(max-width ($screen-lg - 1)) { @content; }
  }
  @else if $media == wide-and-down {
    @include breakpoint(max-width ($screen-xlg - 1)) { @content; }
  }
  @else if $media == wide-large-and-down {
    @include breakpoint(min-width 0) { @content; }
  }

  // If inside of a view, or wider views
  @else if $media == mobile-and-up {
    @include breakpoint(min-width 0) { @content; }
  }
  @else if $media == tablet-and-up {
    @include breakpoint(min-width $screen-xs) { @content; }
  }
  @else if $media == tablet-large-and-up {
    @include breakpoint(min-width $screen-sm) { @content; }
  }
  @else if $media == desktop-and-up {
    @include breakpoint(min-width $screen-md) { @content; }
  }
  @else if $media == wide-and-up {
    @include breakpoint(min-width $screen-lg) { @content; }
  }
  @else if $media == wide-large-and-up {
    @include breakpoint(min-width $screen-xlg) { @content; }
  }
}
