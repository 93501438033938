/**
 * @file
 */

.featured--quotes,
.featured--sponsors {
  position: relative;
  text-align: center;
  //@include make-md-column(6);
  @include respond-to(desktop-and-up) {
    .view {
      //padding: 0 $spacing-double;
    }
  }
  blockquote {
    font-family: $font-family-bold-italic;
  }
  .flexslider .slides img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
}
.featured--sponsors {
  width: 100%;
}
.featured--quotes {
  //padding: 60px 80px;
  blockquote p {
    font-family: $font-family-italic;
    font-size: 30px;
  }
  .flexslider .slides img {
    width: 78px !important;
    height: 78px !important;
    border-radius: 50%;
  }
  .field-item > p:last-child {
    font-size: 15px;
  }
  @include respond-to(tablet-large-and-up) {
    blockquote p {
      font-size: 40px;
    }
  }
}

.featured--map {
  @include respond-to(mobile) {
    #nz_map_default_bg {
      display: none;
    }
  }
  .nz_map_accordian_wrapper {
    max-width: 100%;
  }
  .accordian_list {
    max-width: 100%;
  }
}
