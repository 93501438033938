/*
 * Prose
 *
 * http://www.pearsonified.com/typography/
 * Optimized typography for 16px Font in roughly 700px wide setting
 * (Classic node body area - Add .prose via display suite as required)
 *
 */

.prose {
  @include respond-to(tablet-and-up) {
    line-height: $line-height-base-prose;
    ul,
    ol,
    dt,
    dd {
      line-height: $line-height-base-prose;
    }
    ul,
    ol {
      margin-bottom: ($line-height-prose-computed/2);
      ul,
      ol {
        margin-bottom: 0;
      }
    }
    dl {
      margin-bottom: $line-height-prose-computed;
    }
    blockquote {
      padding: ($line-height-prose-computed / 2) $line-height-prose-computed;
      margin: 0 0 $line-height-prose-computed;
    }
    address {
      margin-bottom: $line-height-prose-computed;
    }
    h1, .h1,
    h2, .h2,
    h3, .h3 {
      margin-top: $line-height-prose-computed;
      margin-bottom: ($line-height-prose-computed/2);
    }
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      margin-top: ($line-height-prose-computed/2);
      margin-bottom: ($line-height-prose-computed/2);
    }
    p {
      margin: 0 0 ($line-height-prose-computed);
    }
    a {
      &:focus {
        outline-offset: 0;
      }
    }
  }
  .lead {
    margin-bottom: $line-height-prose-computed;
  }
}
