.header {

  .right-headers {
    @include respond-to(desktop-and-up) {
    }
  }

  .region-header,
  .region-header2 {
    @include respond-to(desktop-and-up) {
      @include make-row;
    }
  }

  .region-header2 {
    @include respond-to(tablet-and-down) {
      display: none;
    }
  }

}

.navigation--primary {
  // news nav reinstatement
  display: block;  
  .menu {
    display: flex;
  }
  
  
  
  padding: 0;

  /*
  .header & {
    @include respond-to(tablet-and-down) {
      display: none;
    }
    padding-top: $spacing-half;
    @include make-xs-column(12);
    @include respond-to(tablet-and-up) {
      @include make-sm-column(12);
    }
    @include respond-to(desktop-and-up) {
      @include make-md-column(8);
    }
  }
   */
  .post-footer & {
    @include respond-to(tablet-and-down) {
      display: none;
    }
    @include make-xs-column(12);
    @include respond-to(tablet-and-up) {
      @include make-md-column(10);
      @include make-md-column-offset(1);
    }
    .menu-block-wrapper > .menu > .menu__item {
      max-width: 20%; /* only valid if there are 5 top level items */
    }
  }
  .menu {
    float: right;
    .menu {
      display: none;
      .post-footer & {
        display: block;
      }
    }
  }
  .menu__item {
    @include respond-to(desktop-and-up) {
      float: left;
    }
    .header & {
      &:hover .menu {
        display: block;
        position: absolute;
        z-index: 10;
        background-color: $white;
        border-top: 1px solid $pink;
        padding: $spacing-half 0;
        @include box-shadow(0 0 $spacing-quarter rgba(0, 0, 0, 0.4));
        .menu__item {
          width: 100%;
        }
        .menu__link {
          font-size: $font-size-base;
          white-space: nowrap;
          padding: 0 $spacing-base $spacing-half;
        }
      }
    }
    .post-footer & {
      padding-right: $spacing-double;
      &.last {
        padding-right: 0;
      }
      .menu__link {
        font-family: $font-family-bold;
        font-size: $font-size-large;
        padding-left: 0;
        padding-right: 0;
      }
      .menu .menu__item {
        float: none;
        border-top: 1px solid rgba($gray-alt-light, 0.4);
        padding-right: 0;
        .menu__link {
          font-family: $font-family-base;
          font-size: $font-size-small;
          @include respond-to(wide-and-up) {
            font-size: $font-size-base;
          }
        }
      }
    }
  }
  .menu__link {
    .header & {
      font-size: 21px;
      color: $blue;
      font-family: $font-family-bold;
    }
    @include font-smoothing;
    &:hover,
    &:focus,
    &.is-active,
    &.is-active-trail {
      background-color: transparent;
      .header & {
        color: $pink;
      }
    }
  }
}

.navigation--secondary {
  > .block-title {
    font-size: $font-size-h4;
  }
  .menu {
    .menu {
      .menu__link {
        border-bottom: none;
        padding: $spacing-quarter 0 $spacing-quarter $spacing-half;
      }
      .menu {
        .menu__link {
          padding: $spacing-quarter 0 $spacing-quarter $spacing-base;
        }
        .menu {
          .menu__link {
            padding: $spacing-quarter 0 $spacing-quarter $spacing-and-half;
          }
        }
      }
    }
  }
  .menu__item {
    float: none;
  }
  .menu__link {
    background-color: transparent;
    padding: $spacing-quarter 0;
    &:hover,
    &.active {
      background-color: transparent;
      color: $text-color;
    }
    &.active {
      font-weight: bold;
    }
    &:focus {
      background-color: $brand-focused;
      outline-offset: 0;
    }
  }
}

.navigation--tertiary {
  
  body.is-sticky & {
    > .menu-block-wrapper > .menu > .menu__item {
      top: -100vh;
      transition: top 5.55s cubic-bezier(0.215, 0.61, 0.355, 1);
      &.last {
        top: 0;
      }
    }
  }
  
  .header & {
    @include respond-to(tablet-and-down) {
      display: none;
    }
    @include respond-to(tablet-and-up) {
      flex: 1;
    }
  }
  .post-footer & {
    @include make-xs-column(12);
    @include respond-to(tablet-and-up) {
      @include make-md-column(1);
    }
  }


  .menu {
    padding: $spacing-half 0 0;
    @include respond-to(tablet) {
      padding: 0 0 $spacing-base;
    }
    .header & {
      float: right;
      padding: 0 ;
    }
    .post-footer & {
      padding-top: 43px; /* Size of primary nav top level item. */
    }
  }
  > .menu-block-wrapper > .menu > .menu__item {
    @include respond-to(tablet-and-up) {
      margin: 0 16px;
      float: left;
      top: 0;
      transition: top .55s cubic-bezier(.215,.61,.355,1);
    }

    .header & {
      .caret {
        display: none;
      }
      .dropdown-menu > .active > a {
        background-color: transparent;
      }
      .menu {
        display: none;
        position: absolute;
        z-index: 10;
        background-color: $white;
        border-top: 1px solid $pink;
        padding: $spacing-half 0;
        @include box-shadow(0 0 $spacing-quarter rgba(0, 0, 0, 0.4));
        .menu__item {
          width: 100%;

        }
        .menu__link {
          font-size: $font-size-base;
          white-space: nowrap;
          padding: 0 $spacing-base $spacing-half;
        }
      }
      &:hover .menu {
        display: block;
      }
      &:nth-child(odd) {
        // background-color: $pink;
        &:hover,
        &:focus {
          // background-color: darken($pink, 10%);
        }
      }
      &:nth-child(even) {
        // background-color: $blue;
        &:hover,
        &:focus {
          // background-color: darken($blue, 10%);
        }
      }
      &.first {
        @include border-top-left-radius($spacing-quarter);
        @include border-bottom-left-radius($spacing-quarter);
      }
      &.last {
        background-color: $pink;
        @include border-radius($spacing-quarter);
        &:hover {
          background-color: darken($pink, 10%);
        }
        .menu__link {
          color: $white;  
          padding: $spacing-quarter $spacing-half;
        }
      }
    }
    .post-footer & {
      float: none;
      border-top: 1px solid rgba($gray-alt-light, 0.4);
    }
  }
  .menu__link {
    padding: $spacing-quarter 0;
    @include respond-to(tablet-and-up) {
      
    }
    &:hover,
    &:focus {
      background-color: transparent;
    }
    .header & {
      color: $blue;
      font-family: $font-family-bold;
      font-size: 23px;
      &.active,
      &.active:hover {
        color: $pink;
      }
      &:hover {
        color: $pink;
      }
    }
    .post-footer & {
      @include respond-to(wide-and-up) {
        font-size: $font-size-base;
      }
    }
  }
}

.navigation--quaternary {
  border-top: 1px solid $brand-border;
  > .block-title {
    font-size: $font-size-h4;
  }
}
.navigation--site-map {
  .menu {
    > .menu__item {
      > .menu__link {
        font-size: $font-size-h3;
        margin-top: $spacing-base;
        border-bottom: 1px solid $gray-light;
        padding: $spacing-half $spacing-half $spacing-half 0;
        @include respond-to(tablet-and-up) {
          margin-top: $spacing-double;
        }
      }
      > .menu {
        > .menu__item {
          > .menu__link {
            font-size: em(14);
            border-bottom: none;
            margin-top: $spacing-base;
          }
          > .menu {
            border-top: 1px solid $brand-border;
            > .menu__item.is-expanded {
              @include respond-to(tablet-and-up) {
                @include span(4 of 12 after no-gutters);
                &:nth-child(3n+1) {
                  clear: both;
                }
              }
              @include respond-to(desktop-and-up) {
                @include span(2 of 12 after no-gutters);
                &:nth-child(3n+1) {
                  clear: none;
                }
                &:nth-child(6n+1) {
                  clear: both;
                }
              }
              .menu__link {
                margin-top: 0;
                color: lighten($gray-dark, 20%);
              }
              > .menu {
                border-top: none;
                .menu__link {
                  font-size: em(14);
                  margin-left: $spacing-half;
                  border-left: 1px solid $brand-border;
                  padding: $spacing-quarter $spacing-half;
                }
              }
            }
          }
        }
      }
    }
  }
  .menu__link {
    color: $gray-dark;
  }
}
