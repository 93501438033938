/**
 * @file
 */

.webform-client-form {
  margin-top: $spacing-base;
}

.webform-component {
  margin-bottom: $spacing-base;
  &.webform-component-date {
    @extend .clearfix;
    .form-type-select {
      float: left;
      margin-right: $spacing-half;
    }
  }
}

.webform-container-inline {
  input {
    display: initial;
    width: auto;
  }
}

.webform-progressbar {
  .webform-progressbar-outer {
    height: 6px;
    border: none;
    background-color: #878787;
  }
  .webform-progressbar-inner {
    height: 6px;
    background-color: $pink;
  }
  .webform-progressbar-page {
    top: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid #878787;
    border-radius: 6px;
    &.completed,
    &.current {
      border-color: $pink;
      background-color: $white;
    }
  }
}

.webform-component--school-information--attendance-details {
  .webform-component-number {
    @include make-md-column(3);
    &:nth-child(3n-5),
    &:nth-child(3n-6) {
      @include make-md-column-offset(1);
    }
    input {
      /* Make room for % on 'attendance rate'. */
      width: 90%;
      float: left;
    }
    .field-suffix {
      float: right;
      line-height: 33px;
    }
  }
}

.webform-component-draggable-list {
  a.tabledrag-handle .handle {
    background-position-y: 18px;
  }
}
