.toggle {
  float: right;
  clear: both;
  position: relative;
  margin: 0 $spacing-base 0 0;
  padding: 0;
  color: $blue;
  font-size: $font-size-h2;
  line-height: $font-size-base;
  @include respond-to(tablet-and-down) {
    font-size: 20px;
  }
  &:hover,
  &:focus {
    color: $pink;
    text-decoration: none;
  }
  &.active {
    color: $white;
    background-color: $gray-dark;
    .control--search & {
      background-color: transparent;
    }
  }
  .icon--close {
    display: none;
  }
  &.active {
    .icon--close {
      display: inline-block;
    }
  }
}

/*
.toggle--search {
  margin-top: $spacing-half;
  &.active {
    .icon--search {
      display: none;
    }
  }
}
*/

.toggle--navigation {
  &.active {
    .icon--menu {
      display: none;
    }
  }
}

.js-toggle-search {
  background-color: transparent;
  padding: 0;
  &:active {
    box-shadow: none;
  }
  &:hover, &:focus {
    color: $pink;
    background-color: transparent;
  }
  .glyphicon {
    line-height: normal;
    height: 23px;
  }
  .glyphicon-search:before {
    display: block;
    @include respond-to(desktop-and-up) {
      font-size: 19px;
    }
  }
}