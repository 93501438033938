.post-footer {
  .site__inner {
    background-color: $gray-alt;
    color: $gray-alt-light;
    font-size: 14px;
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;
    @include respond-to(desktop-and-up) {
      font-size: 16px;
      padding-top: $spacing-double;
      padding-bottom: $spacing-double;
    }
    a {
      color: $gray-alt-light;
      &:hover {
        color: $white;
      }
    }
  }
}
