.view {
  &.view-custom-view-event-listing {
    .views-row {
      padding: $spacing-base 0;
    }
  }
  .pagination .progress-disabled {
    float: left;
  }
}
