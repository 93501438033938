.related {
  margin: $spacing-half 0 0 0;
	border-top: 1px solid $brand-border;
  @include make-md-column(12);
	> .field {
		@include respond-to(tablet-large-and-up) {
      &:nth-child(even) {
        @include span(6 of 12 before first);
        clear: both;
      }
      &:nth-child(odd) {
        @include span(6 of 12 before last);
      }
    }
	}
  .field-label {
    margin: $spacing-half 0 0 0;
  }
  .field-items {
    margin: $spacing-quarter 0 0 0;
  }
}
