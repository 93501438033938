/*
 * Field
 *
 * [1] Responsive images within the main content areas
 *
 * [2] Add external link icon to links within the WYSIWYG
 */

.field-group-fieldset {
  margin-top: $spacing-half;
  @include respond-to(tablet-and-up) {
    margin-top: $spacing-base;
  }
}

.field-name-body, /* [1] */
.field-name-field-page-banner {
  img {
    max-width: 100%;
    height: auto !important;
    @include respond-to(mobile) {
      width: 100% !important;
    }
  }
}

.field-name-body { /* [2] */
  //a[href^="http://"],
  //a[href^="https://"],
  a[rel*='external'] {
    &:after {
      content: " \e602";
      font-size: em(14);
      @extend %icon;
    }
  }
  iframe {
    max-width: 100%;
  }
}

.field-name-field-file-attachment {
  .file {
    .meta {
      font-size: em(14);
      text-transform: uppercase;
      color: $gray-light;
    }
  }
}

.field-name-commerce-price {
  font-size: $font-size-h2;
  font-family: $font-family-bold;
}

.field-name-field-additional-text {
  margin: $spacing-base 0;
}

.fieldset-legend {
  font-size: $font-size-h3;
  font-family: $font-family-bold-italic;
  margin: $spacing-quarter 0;
}
