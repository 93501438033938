$default-gutter: 20px;

.card {
  .responsive-card-layout__content ul {
    position: relative;
    display: block;
    flex-flow: row wrap;
    margin: 0 (-$default-gutter);
    padding: 0;
    @include respond-to(tablet-and-up) {
      display: flex;
    }
    
  }
  &__item, &--item {
    display: flex;
    flex: 1;
    margin: 0 $default-gutter $default-gutter *2;
    box-shadow: 0px 0px 25px 0px rgba(88,89,91,0.3);
    border: 2px solid $brand-primary;
    border-radius: 6px;
    transition: box-shadow .2s ease-out;
    .group-link {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-content: space-between;
      height: 100%;
      padding: $spacing-double-and-half $spacing-and-half;
    }
    .slat__media {
      width: 100%;
      img {
        margin: 0 auto;
      }
    }
    .slat__title {
      text-align: center;
    }
    .prose {
      color: $gray-alt-dark;
      font-size: 15px;
      text-align: center;
      line-height: 1.5;
    }
    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(88, 89, 91, 0.3);
      transition: box-shadow .2s ease-out;
    }
  }
  &.responsive-card-layout--variant-4 {
    .card__item, .card--item {
      flex: 1 1 calc(50% - #{$default-gutter}*2);
      min-width: 230px;
      @include respond-to(desktop-and-up) {
        flex: 1;
        min-width: 215px;
        margin-bottom: 0;
      }
    }
    
  }
}