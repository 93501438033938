/*
 * Mixins
 *
 * 1. Intrinsic ratios (eg: Used by _responsive-ratio)
 *
 * 2. Cross browser opacity
 *
 * 3. Font smoothing
 *
 * 4. Px to Em https://web-design-weekly.com/snippets/converts-pixels-to-ems-with-sass/
 *
 * 5. WebKit-style focus
 *
 * 6. Flex display.
 *
 * 7. Recreate compass-mixin not included in libsass.
 */

@function aspect-ratio-height($x, $y, $startingWidth: 100%) { /* [1] */
  @return ($y / $x) * $startingWidth;
}

@mixin fix-aspect-ratio($x, $y, $startingWidth: 100%) {
  width: $startingWidth;
  padding-bottom: aspect-ratio-height($x, $y, $startingWidth);
  position: relative;
  overflow: hidden;
}

@mixin opacity($opacity) {  /* [2] */
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin font-smoothing { /* [3] */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) { /* [4] */
  @return #{$pixels/$context}em
}

@mixin tab-focus() {/* [5] */
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin row-eq-height() { /* [6] */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin input-placeholder { /* [7] */
  /* Used in _promotion.scss */
  /* Replaces compass/css/user-interface/input-placeholder(). */
  &::-webkit-input-placeholder  {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1;
  }
  &::-moz-placeholder {
    @content;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin heading-divider($heading-color: $blue-dark, $divider-color:transparentize(#153a70, .8)) {
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 15px;
  color: $heading-color;
  &:before,
  &:after {
    content: '';
    border-top: 1px solid $divider-color;
  }
  
  
  
  
  
  /*
  overflow: hidden;
  text-align: center;
  &:before, &:after {
    background-color: $color;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    right: 0.5em;
    margin-left: -50%;
  }*/
}


