/*
 * Promotion
 *
 * BEM component for promoted content.
 *
 */

a {
  transition-property: color, background-color;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  &:hover {
    transition-property: color, background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in;
  }
}

.promotion--brand {
  align-self: center;
  float: left;
  .logo {
    margin-left: 30px;
    @include respond-to(desktop-and-up) {
      margin-left: 71.25px;
    }
    @include respond-to(mobile-and-down) {
      margin-left: 10px;
    }
  }   
}

.promotion--associated-organisation  {
  text-align: center;
  @include make-xs-column(12);
  padding: $spacing-base 0;
  @include respond-to(desktop-and-up) {
    @include make-md-column(2);
    margin-top: -99px;
    padding: 0;
  }
}

.promotion--social-share {
  position: fixed;
  top: 20%;
  left: 0;
  line-height: 0;
}

.promotion--support {
  text-align: center;
  margin: $spacing-double 0;
  @include respond-to(desktop-and-up) {
    p {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.promotion--events {
  .view.view-custom-view-event-listing {

    body:not(.front) & {
      @extend .responsive-layout--variant-3;
    }
  }
}

.promotion--banner {
  @include respond-to(tablet-large-and-up){
    &-left {
      float: left;
      margin-right: 5% !important;
    }
    float: right;
    margin-left: 5% !important;
    width: 40%;
  }
  
}

.promotion--news {
  .block-title {
    font-size: $font-size-h4;
    display: flex;
    text-transform: uppercase;
    color: #5376a9;
    font-family: $font-family-bold;
    margin-top: $spacing-double-and-half;
    text-align: left;
    &:after {
      align-self: center;
      background-color: #ccd6e5;
      content: "";
      display: block;
      margin-left: 10px;
      height: 1px;
      position: relative;
      flex: 1;
    }
  }
}

.promotion--newsletter {
  background-color: $blue;
  color: white;
  @include make-xs-column(12);
  padding: $spacing-base $spacing-base 222px $spacing-base;
//padding-bottom: 172px; /* for footer-kids image */
  @include respond-to(tablet-and-up) {
    padding-left: $spacing-double * 2;
    padding-right: $spacing-double * 2;
    padding-bottom: 242px;
  }
  @include respond-to(desktop-and-up) {
    @include make-md-column(7);
    padding: 60px 80px;
  }
  > * {
    max-width: 570px;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: white;
    font-family: $font-family-base;
    font-size: 24px;
  }
  p {
    
    line-height: 1.5;
  }
  &:before {
    content: " ";
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 250px;
    height: 172px;
    background-image: url('../images/footer-kids.png');
    background-repeat: no-repeat;
    background-size: 100%;
    @include respond-to(tablet-and-up) {
      width: 321px;
      height: 222px;
    }
    @include respond-to(desktop-and-up) {
      right: -200px;
    }
  }
  .block-title {
    color: $white;
    font-size: $font-size-h4;
    @include respond-to(desktop) {
      font-size: $font-size-base;
    }
  }
  .form-item {
    &:nth-child(-n+2) {
      @include respond-to(desktop-and-up) {
        @include make-md-column(5);
        margin-bottom: $spacing-half;
      }
      @include respond-to(wide-and-up) {
        margin-bottom: $spacing-base;
      }
    }
    &:nth-child(3) {
      @include make-md-column(6);
      @include respond-to(desktop-and-up) {
        @include make-md-column(5);
      }
    }
    &:nth-child(4) {
      @include make-md-column(3.5);
    }
    .form-control {
      border: none;
      background-color: $blue-dark;
      width: 90%;
      color: $white;
      @include input-placeholder {
        color: $white;
      }
    }
  }
  .webform-submit {
    float: left;
    font-size: $font-size-base;
  }
}

.promotion--get-in-touch {
  color: white;
  background-color: $blue-dark;
  font-size: $font-size-h4;
  text-align: center;
  @include make-xs-column(12);
  padding: 60px $spacing-double;
  @include respond-to(desktop-and-up) {
    @include make-md-column(5);
    padding-left: $spacing-double * 2;
    padding-right: $spacing-double * 2;
  }
  @include respond-to(desktop) {
    font-size: 19px;
    //padding-left: $spacing-double * 3;
    //padding-right: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    text-align: right;
  }
  strong {
    color: $white;
  }
  .follow-icons {
    @include respond-to(mobile) {
      display: block;
      a {
        display: inline-block;
        padding-bottom: $spacing-quarter;
      }
    }
  }
}

.promotion--call-to-action {
  clear: both;
  margin: $spacing-half 0;
  @include respond-to(tablet-and-up) {
    margin: $spacing-half $spacing-double;
  }
  border-top: 1px solid #dddddd;
  .media.pull-right {
    margin-left: 60px;
  }
  .prose & blockquote {
    padding: 8px 0;
  }
}

.promotion--partners {
  position: relative;
  flex: 1;
  //margin-bottom: $spacing-base;
  //padding: $spacing-double;
  padding-top: 0;
  //background-color: $gray-alt-lighter;
  text-align: center;
  h2 {
    margin: $spacing-base 0;
    color: $blue;
    font-size: $font-size-h4;
    text-transform: uppercase;
  }
  h5 {
    font-size: 14px;
    font-family: $font-family-bold;
    text-transform: uppercase;
    color: #9B9B9B;
  }
  .partners-wrapper {
    padding: $spacing-and-half;
    padding-bottom: 0;
    @include respond-to(desktop-and-up) {
      display: flex;
    }
    .partners-principal {
      //padding: $spacing-base;
      background-color: $white;
      @include make-xs-column(12);
      @include respond-to(desktop-and-up) {
        @include make-md-column(4);
        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          content: '';
          display: block;
          border-right: 1px solid #D8D8D8;
          height: 50%;
        }
      }
    }
    .partners-platinum {
      @include respond-to(tablet-and-down) {
        margin-top: $spacing-base;
      }
      //padding: $spacing-base;
      background-color: $white;
      @include make-xs-column(12);
      @include respond-to(desktop-and-up) {
        @include make-md-column(7.5);
        @include make-md-column-offset(0.5);
      }
      img { /* bad stuff here (in a rush) */
        width: 50% !important;
        @include respond-to(desktop-and-up) {
          width: 30% !important;
        }
        height: auto !important;
      }
    }
  }
  .more-link {
    position: relative;
    clear: both;
    padding-top: 60px;
    @extend .text-center;
    a {
      @extend .btn;
      @extend .btn-blue;
      &:hover {
        color: $pink;
      }
    }
  }
}

.promotion--copyright {
  @include make-xs-column(12);
}

.promotion--references {
  @include make-xs-column(12);
}
