/*
 * Forms
 *
 * Drupal forms are time consuming to inject with nice BEM like classes
 * so we just style them the old fashioned way...
 */

.input-group {
  z-index: 0;
  .form-control {
    &:first-child {
      @include border-radius(0);
    }
  }
}

.views-exposed-form {
  background-color: $gray-lighter;
  padding: $spacing-half $spacing-base $spacing-base;
  margin: $spacing-half 0;
  @include respond-to(tablet-and-up) {
    margin: $spacing-base 0;
  }
}

.form-number,
.form-email,
.form-text {
  @extend .form-control;
}

.form-item-quantity {
  label {
    display: inline;
    margin-right: $spacing-base;
  }
  input {
    display: inline;
    width: auto;
  }
}

.webform-component--print-button {
  display: inline-block;
  float: right;  
}

.survey .form-actions {
  display: inline-block;
}
