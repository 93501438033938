.content {
  .site__content {
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;
  }
}

.region-post-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
