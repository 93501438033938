/**
 * @file
 */

.nodequeue {
  margin-bottom: $spacing-base;
  text-align: center;

  .block-title {
    font-size: $font-size-h4;
    display: flex;
    text-transform: uppercase;
    color: #5376a9;
    font-family: $font-family-bold;
    margin-bottom: $spacing-base;
    margin-top: $spacing-double-and-half;
    text-align: left;
    &:before, &:after {
      align-self: center;
      background-color: #ccd6e5;
      content: "";
      display: block;
      height: 1px;
      position: relative;
      flex: 1;
    }
    &:before {
      margin-right: 10px;
    }
    &:after {
      margin-left: 10px;
    }
    
  }
  a {
    p {
      color: #333333;
    }
  }

  .centered-nodequeue & {
    text-align: left;

    .block-title:before {
      content: none;
    }
  }
}

.nodequeue--progress,
.nodequeue--why {
  .slat {
    padding: 0 7.5%;
  }
}

.nodequeue--help {
  .block-title {
    color: #8495b7;
  }
}
