body.is-sticky {
  /*.header {
    position: sticky;
    top: 0;
    z-index: 1;
    .site__inner {
      padding: 0;
      transition: padding .55s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }*/
  .header--sticky {
    transform: translateY(0);
    transition: transform .54s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .navigation--tertiary {
    > .menu-block-wrapper > .menu > .menu__item {
      top: -100vh;
      transition: top 2.55s cubic-bezier(0.215, 0.61, 0.355, 1);
      &.last {
        top: 0;
      }
    }
  }
}

.header--sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(-200px);
  transition: transform 1.54s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow:auto;
  z-index: 15;
  .site__inner {
    padding: 10px 0;
    .logo--primary .logo__link {
      height: auto;
      img {
        width: 98px;
      }
    }
  }
  .sponsor-child-btn {
    float: right;
    margin: 0 90px;
    .btn {
      color: $white;
      background-color: $pink;
      font-size: 16px;
      padding: 8px 22px;
      @include respond-to(mobile-and-down) {
        font-size: 17px;
        padding: 5px 10px;
      }
    }
  }
}