.flex {
  display: flex;
  @include respond-to(mobile-and-down) {
    display:block;
  }
  &.flex--space-around {
    justify-content: space-around;
  }
  &.flex--promotion {
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
    .flex__item {
      @include respond-to(mobile-and-down) {
        margin: 20px auto;
      }
      flex: 1;
      align-self: flex-end;
      max-width: 310px;
      margin: 0 20px;
      img {
        margin: 15px 0;
      }
    }
  }
}