/**
 * @file
 *
 * 1. Flexslider ignores any custom classes we put on a flexslider field, so
 *    these are workarounds intended for the 'attached_images' field.
 */

.slider, .flexslider { /* [1] */
  margin-bottom: 0; /* might break the nav and captions */
  border: none;
  .flex-direction-nav {
    @include respond-to(tablet-and-down) {
      display: none;
    }
  }
  .flex-direction-nav a {
    color: $pink;
    text-shadow: none;
    background-color: $pink;
    height: 65px;
    padding: $spacing-base 0;
    @include border-radius($spacing-quarter);
    &:before {
      color: $white;
      font-size: 20px;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
  &:hover .flex-direction-nav a {
    &.flex-prev {
      opacity: 1;
      left: -20px;
    }
    &.flex-next {
      opacity: 1;
      right: -20px;
    }
  }
  .slides {
    .front & {
      display: flex;
    }
    & > li {
      iframe {
        bottom: 0;
        height: 100% !important;
        position: absolute;
        top: 0;
        width: 100%;
        // height: 512px !important;
        // width: 100% !important;
        // @include respond-to(tablet-and-down) {
        //   height: 312px !important;
        // }
        // .front & {
        //   height: 100% !important;
        // }
      }
      img.slider-image--3-2 {
        @include respond-to(desktop-and-up) {
          padding-right: 43.8%; /* for a height of 514px at desktop */
          background-color: $blue-light;
        }
      }
    }
  }
  .flex-caption {
    margin: $spacing-half 0;
  }
  .flex-control-paging {
    position: initial;
    margin-top: $spacing-half;
  }
  .node & { /* [1] */
    .flex-control-paging {
      position: absolute;
      bottom: 0;
      text-align: right;
      li a {
        text-indent: 9999px;
      }
    }
  }
}

.slider--page-banner {
  // &.slider--hero-from-node {
  //   .flex-viewport:before {
  //     content: '';
  //     position: absolute;
  //     z-index: 1;
  //     display: block;
  //     width: 50%;
  //     height: 100%;
  //     background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(125,185,232,0) 50%);
  //     @include respond-to(tablet-and-down) {
  //       width: 100%;
  //     }
  //   }
  // }
  position: relative;
  .flex-viewport {
    @include respond-to(tablet-and-down) {
      background-color: $blue-light;
    }
  }
  .slides > li {
    position: relative;
  }
  .flex-caption {
    background-color: $blue-light;
    padding: $spacing-base $spacing-three-quarters;
    font-size: 24px;
    @include respond-to(tablet-and-up) {
      font-size: $font-size-h2;
      line-height: 38px;
    }
    @include respond-to(desktop-and-up) {
      font-size: $font-size-h1;
      background-color: transparent;
      padding: 0 $spacing-and-half;
      font-size: 40px;
      line-height: 47px;
      position: absolute;
      display: flex;
      align-items: center;
      width: 43.8%; /* see slider-image--3-2 */
      height: 100%;
      top: 0;
      right: 0;
    }
    margin: 0;
    line-height: 28px;
    font-family: $font-family-bold-italic;
    text-align: center;
    color: white;
  }


  .left-and-blue.flex-caption,
  .left-and-white.flex-caption {
    background-color: #f8f9fc;
    @include respond-to(desktop-and-up) {
      background-color: transparent;
      font-size: 40px;
      line-height: 47px;
      padding: 0 90px;
      width: 600px;// 46.8%; /* see slider-image--3-2 */
      left: 0;
      right: inherit;
    }
    font-family: $font-family-black;
    text-align: left;
    color: $blue-dark;
  }
  .left-and-white.flex-caption {
    color: $white;
    @include respond-to(tablet-and-down) {
      color: $blue-dark;
    }
  }
  .flex-control-nav  {
    margin: 0;
  }


  .flex-control-paging {
    background-color: #f8f9fc;
    z-index: 1;
    bottom: -30px;
    padding-top: 10px;
    body:not(.front) & {
      display: none;
    }
  }
  .field-name-field-page-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    max-width: 500px;
    margin: 0;
    padding: 0 20px 0 40px;
    color: white;
    font-size: 26px;
    line-height: 1.2;
    font-family: $font-family-italic;
    .btn {
      margin-top: 20px;
    }
    @include respond-to(tablet-and-up) {
      font-size: 45px;
    }
    @include respond-to(tablet-large-and-up) {
      font-size: 55px;
      padding: 0 0 0 80px;
    }
  }
  .front & .flex-caption {
    display: none;
  }
} 