.front {
  .site.content {
    .site__inner {
      padding-left: 0;
      padding-right: 0;
    }

    .site__content  {
      /* Site gutters */
      //background-color: #f8f9fc;
      margin-bottom: $spacing-double;
      padding: $spacing-base;
      @include respond-to(desktop-and-up) {
        //background-color: #f8f9fc;
        margin-bottom: $spacing-triple; 
        padding: $spacing-double ($spacing-double * 2) 0;
      }
    }
  }

  .node-page .field-name-body {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    h3.lead {
      font-family: $font-family-italic;
      color: $blue;
    }
    .btn {
      font-size: 26px;
      margin-top: $spacing-half;
    }
    @include respond-to(tablet-and-up) {
      padding: 40px 60px 0;
    }
  }

  // region post content
  .promotion--events,
  .promotion--news {
    @include respond-to(tablet-and-up) {
      flex: 0 0 48%;
      padding-right: $spacing-double;
    }

    .block-title {
      margin-top: 0;
    }
  }

  .promotion--news {

  }

  .promotion--events {
    .view.view-custom-view-event-listing .views-row {
      .slat__title h4 {
        font-size: $font-size-h3;
      }
    }
  }

  .slat__title h4,
  .slat__title {
    font-family: $font-family-bold;
  }
}