.logo__link {
  display: block;
  line-height: 0;
}

.logo--primary {
  .logo__link {
    @include respond-to(tablet-and-down) {
      text-align: center;
      img {
        width: 143px;
      }
    }
    @include respond-to(desktop-and-up) {
      width: 241px;
      height: 79px;
    }
  }
}

.logo--secondary {
  img {
    @include respond-to(desktop-and-up) {
      width: 100%;
      padding-right: $spacing-base;
    }
  }
}