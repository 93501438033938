.site {
  @include respond-to(tablet-and-down) {
    //padding-left: $spacing-mobile-outer-gutter;
    //padding-right: $spacing-mobile-outer-gutter;
  }
}

.site__inner {
  @extend .container;
  background-color: $white;
  @include respond-to(wide-large) {
    width: 1380px;
  }
  /* Site gutters */
  padding: 0 $spacing-base;
  @include respond-to(desktop-and-up) {
    padding: 0 ($spacing-double * 2);
  }
  .post-content & {
    padding-bottom: $spacing-half;
  }
}

.site__row {
  @extend .row;
}

.site__content {
  @include make-md-column(12);
  &.has-sidebar {
    @include make-md-column(8);
    @include respond-to(desktop-and-up) {
      border-right: 1px solid $brand-border;
      padding: 0 $spacing-double 0 0;
    }
  }
}

.site__sidebar {
  @include make-md-column(4);
}

.site__sidebar-inner {
  .block {
    padding-bottom: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    padding: 0 0 0 $spacing-and-half;
  }
}
