/*
 * Susy Grid
 *
 * Ignite utilises Susy to as a powerful/flexible utility grid.
 * Susy is not used as the sites main frame as Bootstrap handles this.
 * Susy is useful when we need close control of elements such as _slat layout.
 *
 * Set up our susy grid defaults
 * http://www.zell-weekeat.com/susy2-tutorial/
 * http://susy.readthedocs.org/en/latest/toolkit/
 */
$susy: (
  columns: 12,
  container: $container-lg,
  math: magic,
  output: float,
  gutters: 2/3,
  gutter-position: inside,
  global-box-sizing: content-box,
  debug: (
    image: hide,
    color: rgba(#bada55, .50),
    output: background,
    toggle: top right,
  ),
);

/* http://susy.readthedocs.org/en/latest/settings/#custom-rem */
$rem-with-px-fallback: true;
