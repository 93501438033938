.control--menu {
  @include make-xs-column(1);
  padding-top: $spacing-quarter;
  @include respond-to(desktop-and-up) {
    display: none;
  }

  .toggle {
    margin-right: $spacing-half;
    @include respond-to(tablet-and-up) {
      margin-right: $spacing-base;
    }
  }

}

.control--search {
  @include make-xs-column-offset(2);
  @include make-xs-column(1);
  padding-top: $spacing-quarter;
  @include respond-to(tablet-and-up) {
    @include make-xs-column-offset(0);
    @include make-xs-column(1);
  }
  @include respond-to(desktop-and-up) {
    @include make-md-column(1);
    padding-top: $spacing-quarter;
  }
  .toggle {
    margin-left: $spacing-quarter;
  }
}
