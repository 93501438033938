.nav {
  > li {
    > a {
      &:focus {
      }
    }
  }
}
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
}