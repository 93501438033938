.node__title {
  .title {
    margin-top: 0;
  }
}

.node__date {
  @extend .date;
}

.node-webform {
  padding-bottom: $spacing-base;
}

.node-feature {
  .field-name-field-teaser-image {
    img {
      margin: 0 auto;
    }
  }
}