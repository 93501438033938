/*
 * Search
 *
 * 1. Hide the search (tablet and down) within the site header context but
 *    not the javascript cloned mmenu copy
 * 2. Search styling.
 */

/* [1] */
.search--small {
  .header & {
    display: none;
    @include respond-to(desktop-and-up) {
      display: block;
    }
  }
  @include respond-to(mobile-and-up) {
    @include make-xs-column(12);
  }
  @include respond-to(desktop-and-up) {
    @include make-md-column(5);
    @include make-md-column-offset(1);
  }
  .btn {
    border-radius: 0;
  }
}

/* [2] */
.search,
.search-form,
#block-mmenu-search {
  .btn-default,
  .btn-primary{
    background: $white;
    border-color: #ccd6e4;
    @include border-top-right-radius($spacing-quarter);
    @include border-bottom-right-radius($spacing-quarter);
    height: 38px; /* Same as buttons to the right of it */
    &:hover {
      background-color: $white;
      border-left: 0;
    }
    .glyphicon-search {
      color: $blue;
      font-weight: bold;
    }
  }
  .form-search#search-block-form {
    /*float: right;*/
  }
  .site-wrapper & .input-group .form-text, /* workaround */
  .input-group .form-text {
    border-color: #ccd6e4;
    border-right: 0;
    @include border-top-left-radius($spacing-quarter);
    @include border-bottom-left-radius($spacing-quarter);
    height: 38px; /* Same as buttons to the right of it */
  }
}

.search-result {
  margin-bottom: $spacing-and-half;
}

.page-search .region-content h2 {
  display: none;
}
