.header {
  .site__inner {
    background-color: $gray-alt-lighter;
    padding: $spacing-half 0;
    @include respond-to(desktop-and-up) {
      padding: $spacing-base 0;
    }
    .site__row {
      @include respond-to(tablet-and-down) {
        display: flex;
        .right-headers {
          flex: 1;
          align-self: center;
        }
      }
    }
  }
  .sponsor-child-btn {
    //display: none;
    margin: 0 20px;
    @include respond-to(tablet-and-down) {
      display: block;
    }

    .btn {
      color: $white;
      background-color: $pink;
      @include respond-to(mobile-and-down) {
        font-size: 17px;
        padding: 5px 10px;
      }
    }
  }
}

.region-header {
  display: flex;
	justify-content: flex-end;
  align-items: center;
  @include respond-to(tablet-and-up) {
    margin: 20px 0;
  }
  @include respond-to(tablet-and-down) {
    justify-content: flex-end;
  }
}