/*
 * Slat (Previously _teaser)
 *
 * A slat is kind of like a Drupal teaser (view mode) but the more ambiguous name
 * means it is more versatile and can be used outside of the view mode context.
 *
 * The 'slat' idea was derived from http://pea.rs/content/slats-html5
 *
 * Here we establish some base level BEM-style slat behaviour
 * Use these classes to theme slat like elements like teasers eg: via Display Suite.
 *
 * Note: We liberally mix in some OOCSS style selectors (Amoungst our BEM)
 * to make things more intuitive for themers in the backend.
 *
 * 1. Components
 *
 * 2. Variants
 *
 */

$slat-border: 1px solid darken($gray-lighter, 10%);
$slat-background-color: $gray-lighter;
$slat-text-color: $text-color;

/* [1] */
.slat__media {
  > a {
    display: block;
  }
}

.slat__title {
  h4, &.h4 {
    color: $black;
    font-family: $font-family-bold-italic;
  }
  a {
    &:hover,
    &:focus {
      color: $slat-text-color;
      @include single-transition(all, 0.05s, ease-in);
    }
    &:visited {
      //color: $brand-visited;
    }
  }
  @include respond-to(mobile) {
    font-size: $font-size-h4;
  }
  .site__sidebar & {
    font-size: $font-size-h4;
  }
}

.slat {
  .btn.btn-default {
    font-size: $font-size-base;
    margin-top: $spacing-base;
  }
}

.slat__body {
  .lead {
    font-size: $font-size-large;
  }
}

.slat__read-more {
  margin-top: $spacing-half;
  > a {
    color: $gray-dark;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.slat__date {
  @extend .date;
}

.slat__event-date {
  @extend .slat__date;
  position: relative;
  .field-items {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    // padding-bottom: $spacing-quarter;
    .field-item {
      display: inline-block;
      padding: $spacing-quarter $spacing-half;
      color: $white;
      background: $pink;
      font-size: 22px;
      font-family: $font-family-bold;
    }
  }
}

.slat__term {
  font-size: em(14);
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}

.slat__body {

}

.slat__link {
  position: relative;
  float: left;
  width: 100%;
  display: block;
  .slat__title {
    color: $slat-text-color;
    @include single-transition(none);
  }
  .slat__body {
    color: $slat-text-color;
  }
  .slat__date {
    color: lighten($gray-light,10%);
  }
  &:hover,
  &:focus {
    text-decoration: none;
    .slat__title {
      color: $slat-text-color;
      @include single-transition(all, 0.05s, ease-in);
    }
    .slat__body {
      color: $slat-text-color;
    }
    .slat__date {
      color: lighten($gray-light,10%);
    }
  }
  &:focus {
    background-color: transparent;
  }
}

/* [2] */
.slat--has-top-border {
  border-top: $slat-border;
}

.slat--has-top-margin {
  margin-top: $spacing-base;
  @include respond-to(tablet-and-up) {
    margin-top: $spacing-and-half;
  }
}

.slat--has-background-colour {
  background-color: $slat-background-color;
  .slat__content {
    padding: 0 $spacing-base $spacing-base;
  }
}

.slat--sometimes-has-left-aligned-media,
.slat--sometimes-has-right-aligned-media {
  .slat__media {
    margin-bottom: $spacing-base;
  }
  .slat__content {
    padding: 0 0 $spacing-half 0;
  }
  &.slat--has-background-colour {
    .slat__content {
      padding: 0 $spacing-half $spacing-half;
      @include respond-to(tablet-and-up) {
        padding: 0 $spacing-three-quarters $spacing-half 0;
      }
    }
  }
}

.slat--sometimes-has-left-aligned-media {
  .slat__media {
    @include respond-to(tablet-and-up) {
      @include span(5 of 12 before first);
      margin-right: $spacing-and-half;
    }
  }
}

.slat--sometimes-has-right-aligned-media {
  .slat__media {
    @include respond-to(tablet-and-up) {
      @include span(5 of 12 before last);
      margin-left: $spacing-and-half;
    }
  }
}

.slat--always-has-left-aligned-media,
.slat--always-has-right-aligned-media {
  &.slat--has-background-colour {
    @include clearfix;
    .slat__content {
      padding: 0 $spacing-half $spacing-half;
      @include respond-to(tablet-and-up) {
        padding: 0 $spacing-three-quarters $spacing-half 0;
      }
    }
  }
}

.slat--always-has-left-aligned-media {
  @include respond-to(tablet-and-up) {
    .slat__media {
      @include span(5 of 12 before first);
    }
    .slat__content {
      @include span(7 of 12 before last);
    }
  }
}

.slat--always-has-right-aligned-media {
  @include respond-to(tablet-and-up) {
    .slat__media {
      @include span(5 of 12 before last);
    }
    .slat__content {
      @include span(7 of 12 before first);
    }
  }
}
