/*
 * Helpers
 *
 * 1. Useful within Display suite for removing margin from headings.
 *
 * 2. https://github.com/twbs/bootstrap/pull/12679
 *    Use in conjunction with .sr-only to only display content when it's focused.
 *    Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 *
 * 3. Bootstrap gutter killer
 *
 */

.has-no-margin-top { /* [1] */
  margin-top: 0!important;
}

.sr-only-focusable { /* [2] */
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.no-gutter { /* [3] */
  &[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.has-margin-bottom {
  margin-bottom: $spacing-half;
}

.has-margin-bottom-large {
  margin-bottom: $spacing-double;
  @include respond-to(tablet-large-and-up) {
    margin-bottom: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    margin-bottom: $spacing-triple;
  }
}


.tablet-and-up-only {
  @include respond-to(tablet-and-down) {
    display: none;
  }
}
.mobile-only {
  @include respond-to(desktop-and-up) {
    display: none;
  }
}

.break-out {
  padding: 30px 0px;
  @include respond-to(tablet-large-and-down) {
    &:before {
      content: '';
      width: 100vw;
      background-color: white;
      position: absolute;
      z-index: 0;
      height: 100%;
      left: 50%;
      top: 0;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: block; 
    }
  }
  @include respond-to(tablet-and-up) {
    width: calc(100% + #{$spacing-base *2}) !important;
    margin-left: -$spacing-base;
    margin-right: -$spacing-base;
    padding: 60px 80px 30px;
  }
  @include respond-to(desktop-and-up) {
    width: calc(100% + #{$spacing-double *2} *2) !important;
    margin-left: -($spacing-double * 2);
    margin-right: -($spacing-double * 2);
  }
}

.has-heading-divider h2 {
  margin: 0 0 60px;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #5376a9;
  font-family: "NationalBold", arial, sans-serif;
  @include heading-divider();
}