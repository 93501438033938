/*
 * Drupal
 *
 * Custom tweaks to admin elements
 */
.alert {
  margin-top: $spacing-base;
  .close {
    line-height: 0.8em;
  }
}

.tabs--primary {
  margin: $spacing-quarter 0;
}

.contextual-links-wrapper {
  a {
    &:focus {
      background-color: transparent;
      @include tab-focus;
    }
  }
}

a {
  &:focus {
    #admin-menu & {
      background-color: transparent;
      outline: none;
      outline-offset: 0;
    }
  }
}

#admin-menu input {
  height: auto;
}
