.footer {
  margin: 0;
  border: 0;
  padding: 0;
  .site__inner {
    padding: 0; /* we need this to be set by the blocks */
    @include respond-to(desktop-and-up) {
      .region-footer {
        @include row-eq-height();
      }
    }
  }
}

.region-post-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.region-pre-footer {
  .nodequeue {
    .block-title {
      margin-top: 0;
    }
  }
}