/**
 * @file Typography fonts and styles.
 *
 * [1] Fonts.
 *
 * [2] Styles.
*/

/* [1] */
@font-face {
  font-family: 'NationalRegular';
  src: url('../fonts/national/National-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalBold';
  src: url('../fonts/national/National-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalItalic';
  src: url('../fonts/national/National-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalBoldItalic';
  src: url('../fonts/national/National-BoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalBlack';
  src: url('../fonts/national/National-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalBlack';
  src: url('../fonts/national/National-BlackItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

/* [2] */
body {
  font-family: $font-family-base;
  font-size: $font-size-large;
  line-height: $line-height-base;
  min-width: 320px;
  background-color: #dddddd;
  @include font-smoothing();
  @include respond-to(tablet-and-down) {
    font-size: 18px;
  }
  -webkit-user-select: initial !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}
h1, .h1,
h2, .h2,
h3, .h3 {
  //color: $blue;
  font-family: 'NationalBoldItalic', arial, sans-serif;
  margin-top: ($line-height-computed/2);
  margin-bottom: ($line-height-computed/2);
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($line-height-computed/2);
  margin-bottom: ($line-height-computed/2);
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

p {
  margin: 0 0 ($line-height-computed/2);
  &:last-child {
    margin: 0;
  }
  a {
    &:focus {
      outline-offset: 0;
    }
  }
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: 30px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

blockquote {
  font-family: 'NationalItalic', arial, san-serif;
  quotes: '\201c' '\201d';
  border-left: 0;
  p {
    font-size: 22px;
    &:first-child:before {
      content: open-quote;
    }
    &:last-child:after {
      content: close-quote;
    }
  }
}

strong {
  font-weight: normal;
  font-family: $font-family-bold;
  em &,
  & em {
    font-family: $font-family-bold-italic;
  }
}

em {
  font-style: normal;
  font-family: $font-family-italic;
}
