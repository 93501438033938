/**
 * @file
 *
 * 1. Some buttons have their 'a' element nested instead of having the
 *    btn class directly on the 'a' itself.
 */

.btn {
  font-family: $font-family-bold;
  font-size: 20px;
  padding: 10px 22px;
  @include respond-to(mobile) {
    max-width: 100%;
    white-space: normal;
  }
}

.btn-lg {
  margin: 10px 0;
}

.btn-mega {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  @include respond-to(tablet-and-up) {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px;
  }
}

.btn-default {
  a { /* [1] */
    color: $white;
    text-decoration: none;
  }
}

.btn-inverted {
  color: $pink;
  font-size: 18px !important;
  text-decoration: none;
  border:1px solid $pink;
  &:hover {
    color: $blue;
  }
}

.btn-blue {
  @include button-variant($blue-dark, transparent, $blue-dark);
}

.btn-white {
  @include button-variant(#00ABBC, white, white);
}
.btn-info {
  @include respond-to(mobile) {
    margin-top: $spacing-base;
  }
}

/* Breaks the rules a little, but needed for cart block. */
.line-item-summary .links a {
  @extend .btn;
  @extend .btn-default;
}
